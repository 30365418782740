import { faCartShopping } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, Modal } from "react-bootstrap"
import * as globalCss from "../../components/styles/global.module.css"
import * as css from "./ModalBeforeDownload.module.css"

const ModalBeforeDownload = (props: any) => {

    return (
        <>
            <Modal
                show={props.isPaymentInfoModalVisible}
                onHide={() => { props.setIsPaymentInfoModalVisible(false) }}
                className={css.currentModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sebelum Melakukan Pembayaran</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Pastikan beberapa hal berikut:
                    <ul>
                        {
                            props.itemType != null && props.itemType == 'subscription' ? (
                                <>
                                    <li>Pembelian paket Pro akan terakumulasi jika sebelumnya pernah membeli.</li>
                                </>
                            ) : (
                                <>
                                    <li>Informasi sudah sesuai dan foto anabul sudah muncul, jika belum silahkan kembali ke tahap sebelumnya.</li>
                                </>
                            )
                        }

                        {
                            props.totalAmount != null && props.totalAmount < 10000 ? (
                                <>
                                    <li>Pembayaran dibawah Rp10,000 hanya bisa menggunakan GoPay atau QRIS.</li>
                                </>
                            ) : null
                        }

                        <li>Jika kamu menggunakan selain GoPay (contoh: Dana, ShopeePay, atau Ovo), silahkan pilih GoPay dan scan kode QR yang muncul.</li>
                        <li>Setelah pembayaran, kamu akan menerima e-mail panduan selanjutnya.</li>
                        <li>Jika email datang terlambat,{' '}
                            kamu bisa membuka halaman <FontAwesomeIcon icon={faCartShopping} /> Riwayat Pembelian untuk mengunduh KTP.</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>

                    <div className={css.mobileView}>

                        <div className="d-grid gap-2">

                            {
                                props.paymentWindowUrl != null ? (
                                    <a href={props.paymentWindowUrl} target="_blank">
                                        <Button>
                                            Buka Jendela Pembayaran
                                        </Button>
                                    </a>
                                ) : (
                                    <Button variant={'primary'} disabled={true}>
                                        <div className={globalCss.dotLoading}>Menyiapkan Jendela Pembayaran</div>
                                    </Button>
                                )
                            }

                            <Button variant={'outline-secondary'}
                                onClick={() => props.setIsPaymentInfoModalVisible(false)}
                            >
                                Kembali
                            </Button>
                        </div>
                    </div>
                    <div className={css.desktopView}>
                        <Button variant={'outline-secondary'}
                            onClick={() => props.setIsPaymentInfoModalVisible(false)}
                        >
                            Kembali
                        </Button>

                        {
                            props.paymentWindowUrl != null ? (
                                <>
                                    <a href={props.paymentWindowUrl} target="_blank"
                                    >
                                        <Button>
                                            Buka Jendela Pembayaran
                                        </Button>
                                    </a>
                                </>
                            ) : (
                                <Button variant={'primary'} disabled={true}>
                                    <div className={globalCss.dotLoading}>Menyiapkan Jendela Pembayaran</div>
                                </Button>
                            )
                        }
                    </div>


                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalBeforeDownload
